import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {Button, Form, InputGroup} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {Requests} from '../../../controller/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {AccountData, FormError} from '../../../db/types';
import {useFormErrors} from '../../../hooks/useFormErrors';
import {getError, hasError} from '../../../utils/common/errors';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export type Props = {
    onCancel: () => void,
    onSignUp: () => void,
    onSuccess: (accountData: AccountData) => void,
    onForgotPassword: () => void,
    message?: string,
}


export default function SignIn({onCancel, onSuccess, onSignUp, message, onForgotPassword}: Props) {
    const {addError, RenderedErrors, clearErrors, hasErrors, errors} = useResponseErrors();
    const {addError: addFormError, clearErrors: clearFormErrors, hasErrors: hasFormErrors, errors: formErrors, setErrors: setFormErrors} = useFormErrors();
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const ref = useRef<HTMLFormElement|null>(null)

    const hasGeneralError = useMemo(() => {
        return hasError('general', formErrors);
    }, [formErrors]);

    const onSignIn = useCallback(() => {
        clearErrors();
        clearFormErrors();

        if (!email) {
            addFormError({message: "Please enter a valid email.", key: "email"});
            return;
        }


        if (!password) {
            addFormError({message: "Please enter your password.", key: "password"});
            return;
        }

        setIsProcessing(true)
        const data = {
            email: email,
            password: password,
        }

        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.signIn,
            data: {...data},
        }).then(response => {
            setIsProcessing(false)

            setEmail('');
            setPassword('');
            if (response.error || response.formErrors) {
                response.error && addError(response.error);
                setFormErrors(response.formErrors || [])
                return;
            }

            window.location.reload();
            onSuccess(response.data as AccountData)
        }).catch(error => {
            setIsProcessing(false)
            console.error(error)
        });
    }, [ref, email, clearErrors, password, errors, clearFormErrors, formErrors]);

    return (<>

        {hasErrors && <RenderedErrors className="mb-2" />}
        {hasGeneralError && <ErrorMessage errors={[getError('general', formErrors) as FormError]} />}

        <Form ref={ref}>
            <Form.Group>
                <Form.Label className="text-white  mb-2">Email</Form.Label>
                <InputGroup className="custom-input-group">
                    <InputGroup.Text
                        className={`default-background custom-input-group ${
                            hasError('email', formErrors) ? 'is-invalid' : ''
                        }`}
                    >
                        <span className="ti-email" />
                    </InputGroup.Text>
                    <Form.Control
                        style={{ borderLeft: '0px' }}
                        className="default-background"
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        isInvalid={hasError('email', formErrors)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {getError('email', formErrors)?.message}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mt-3">
                <Form.Label className="text-white mb-2">Password</Form.Label>
                <Button
                    onClick={() => {
                        clearErrors()
                        clearFormErrors()
                        onForgotPassword();
                    }}

                    variant="outline-primary"
                    className="float-end border-0"
                    style={{ position: 'relative', top: '-8px' }}
                >
                    Lost your password?
                </Button>
                <InputGroup className="custom-input-group">
                    <InputGroup.Text
                        className={`default-background ${
                            hasError('password', formErrors) ? 'is-invalid' : ''
                        }`}
                    >
                        <span className="ti-key" />
                    </InputGroup.Text>
                    <Form.Control
                        style={{ borderLeft: '0px' }}
                        className="default-background"
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        isInvalid={hasError('password', formErrors)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {getError('password', formErrors)?.message}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
                <div className="d-flex">
                    <Button type="button" variant="outline-primary" size="sm" className="me-2" onClick={onCancel}>Cancel</Button>
                    <Button type="button" variant="outline-primary" size="sm" onClick={onSignUp}>Sign Up</Button>
                </div>


                <PrimaryButton type="button" size="sm" onClick={onSignIn} isProcessing={isProcessing}>Sign In</PrimaryButton>
            </div>
        </Form>
    </>);

}