import {Requests} from '../../../../controller/requests';
import {getTimestampId} from '../../../../utils/common/getTimestampId';
import {sendRequest} from '../../../../api/frontend/api';
import {filterTaxonomiesByEntities} from '../../../../utils/common/taxonomies';


export default function makeListTaxonomiesLoader(entity: string) {
    return async function listTaxonomiesLoader({params, request}) {
        const searchParams = new URL(request.url).searchParams;

        const response = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listTaxonomies,
            data: {
                query: searchParams.get('query') || '',
                type: params.type,
                context: params.context,
            }
        });

        const items = filterTaxonomiesByEntities(response.data?.taxonomies || [], ['all', entity]);

        return {items, pointer: response.data?.pointer, type: params.type , entity: entity, routeContext: params.context, collectionEntities: ['all', entity], error: response.error};
    }
}
