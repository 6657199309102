import {RouteObject} from 'react-router-dom';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';
import React from 'react';
import BaseLayout from '../../../components/Layout/Pwa/BaseLayout';

const routes: RouteObject =  {
    path: undefined,
    element: <BaseLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/',
            element:  <div />
        },
        {
            loader: async ({params}) => {
                return params || {};
            },
            path: '/messages/:messageId?',
            element: <MessageScreen />,
        },
    ]
};

export default routes;