
/**
 * Is the page currently in standalone display mode (used by PWA)?
 * @return {boolean}
 */
function isInStandaloneMode() {
    return Boolean(
        (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
        || (window.navigator as any).standalone, // Fallback for iOS
    );
}


export function isInstalledPwaSession() {
    if (isTwaSession()) {
        return false;
    }

    let hasBeenInStandaloneMode;
    if (isInStandaloneMode()) {
        hasBeenInStandaloneMode = true;
        sessionStorage.setItem('is_standalone_mode', 'yes');
    } else {
        hasBeenInStandaloneMode = sessionStorage.getItem('is_standalone_mode') === 'yes';
    }

    return hasBeenInStandaloneMode;
}

export function isTwaSession() {
    // Check for #play-store-twa hash in start URL to identify TWA
    let hasPlayStoreTwaHash;
    if (window.location.pathname === '/' && window.location.hash === '#play-store-twa') {
        hasPlayStoreTwaHash = true;
        sessionStorage.setItem('is_play_store_twa', 'yes');
    } else {
        hasPlayStoreTwaHash = sessionStorage.getItem('is_play_store_twa') === 'yes';
    }

    return hasPlayStoreTwaHash;
}