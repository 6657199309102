import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {Alert, Button, Form, InputGroup} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {Requests} from '../../../controller/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {AccountData, FormError} from '../../../db/types';
import {useFormErrors} from '../../../hooks/useFormErrors';
import {getError, hasError} from '../../../utils/common/errors';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export type Props = {
    onCancel: () => void,
    onSuccess?: () => void,
    message?: string,
}


export default function ForgotPassword({onCancel, onSuccess, message}: Props) {
    const {addError, RenderedErrors, clearErrors, hasErrors, errors} = useResponseErrors();
    const {addError: addFormError, clearErrors: clearFormErrors, hasErrors: hasFormErrors, errors: formErrors, setErrors: setFormErrors} = useFormErrors();
    const [email, setEmail] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const ref = useRef<HTMLFormElement|null>(null)
    const [successMessage, setSuccessMessage] = useState('');

    const hasGeneralError = useMemo(() => {
        return hasError('general', formErrors);
    }, [formErrors]);

    const onSignIn = useCallback(() => {
        clearErrors();
        clearFormErrors();


        if (!email) {
            addFormError({message: "Please enter a valid email.", key: "email"});
            return;
        }


        setIsProcessing(true)
        const data = {
            email: email,
        }

        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.forgotPassword,
            data: {...data},
        }).then(response => {
            setIsProcessing(false)
            if (response.error || response.formErrors) {
                response.error && addError(response.error);
                setFormErrors(response.formErrors || [])
                return;
            }

            setEmail('');
            setSuccessMessage("Please check you email. We've just a message with a link that will help you to reset your password...")
            onSuccess && onSuccess()
        }).catch(error => {
            setIsProcessing(false)
            console.error(error)
        });
    }, [ref, email, clearErrors, clearFormErrors, errors]);

    return (<>

        {hasErrors && <RenderedErrors className="mb-2" />}
        {hasGeneralError && <ErrorMessage errors={[getError('general', formErrors) as FormError]} />}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        <Form ref={ref}>
            <Form.Group className="mb-4">
                <Form.Label className="mb-2 text-white">Email</Form.Label>
                <InputGroup className="custom-input-group">
                    <InputGroup.Text
                        className={`default-background ${
                            hasError('email', formErrors) ? 'is-invalid' : ''
                        }`}
                    >
                        <span className="ti-email" />
                    </InputGroup.Text>
                    <Form.Control
                        style={{ borderLeft: '0px' }}
                        className="default-background"
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        isInvalid={hasError('email', formErrors)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {getError('email', formErrors)?.message}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
                <Button type="button" variant="outline-primary" size="sm" className="me-2" disabled={isProcessing} onClick={onCancel}>Cancel</Button>
                <PrimaryButton type="button" size="sm" onClick={onSignIn} isProcessing={isProcessing}>Reset</PrimaryButton>
            </div>
        </Form>
    </>);

}