import React, {PropsWithChildren} from 'react';
import classnames from 'classnames';
import {Col, Row} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import ApplicationMenu from '../../Common/ApplicationMenu/AppliatoinMenu';

export default function ApplicationLayout({children, className, style}: PropsWithChildren<DefaultProps>) {
    return (
        <div className={classnames("application-layout", className)} style={{...style}}>
            <Row className="w-100">
                <Col xs={2} sm={2} lg={1} className="position-relative">
                    <ApplicationMenu />
                </Col>
                <Col xs={10} sm={9} lg={6} xl={5}>
                    {children}
                </Col>
            </Row>
        </div>
    )
}