import {Outlet, useLoaderData, useLocation, useMatch} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {
    routeEntityTaxonomiesEdit,
    routeEntityTaxonomiesList, routeGoalsList, routeGoalsEdit,
} from '../../../routes';
import LayoutNav, {LayoutNavItem} from '../../Common/LayoutNav/LayoutNav';
import ApplicationLayout from './ApplicationLayout';
import {makeRouteMatch} from '../../Mobile/Menu/Menu';
import {Goal} from '../../../db/types';
import GoalHeader from '../../Common/Context/GoalHeader/GoalHeader';

export type Props = DefaultProps;

export function getNavItems(path: string, context: string): LayoutNavItem[] {
    return [
        {
            to: routeGoalsList(context),
            text: 'Goals'
        },
        {
            to: routeEntityTaxonomiesList(path, 'collection', context),
            text: 'Collections'
        },
        {
            to: routeEntityTaxonomiesList(path, 'tag', context),
            text: 'Tags'
        },
    ];
}

export function getContentAwareNavLink(url?: string, context?: string): LayoutNavItem {
    if (url?.includes(routeEntityTaxonomiesList(url, 'collection', context)) || url?.includes(routeEntityTaxonomiesEdit(url, 'collection', context))) {
        return {text: 'Add Collection', to: routeEntityTaxonomiesEdit(url, 'collection')};
    }

    if (url?.includes(routeEntityTaxonomiesList(url, 'tag', context)) || url?.includes(routeEntityTaxonomiesEdit(url, 'tag', context)) ) {
        return {text: 'Add Tag', to: routeEntityTaxonomiesEdit(url, 'tag', context)};
    }

    return {text: 'Add Goal', to: routeGoalsEdit(undefined, context)};
}

export default function GoalsLayout({style, className}: Props) {
    const {pathname} = useLocation();
    const match = useMatch(makeRouteMatch(pathname));
    const context = match?.params?.param2 || 'all';
    const {goal} = useLoaderData() as {goal?: Goal};

    const [addItem, setAddItem] = useState(getContentAwareNavLink(pathname, context));


   useEffect(() => {
       setAddItem(getContentAwareNavLink(pathname, context));
   }, [pathname]);

    return (
        <ApplicationLayout className={classnames(className)} style={{...style}}>
            {goal && <GoalHeader goal={goal} />}
            {!pathname.includes('/overview') && <LayoutNav addItem={addItem} items={getNavItems(pathname, context)} />}
            <Outlet />
        </ApplicationLayout>)
}