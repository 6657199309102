import {useNavigate, useRouteError} from 'react-router-dom';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import React from 'react';
import {routeNotesList} from '../../../routes';

export default function ApplicationError() {
    const navigate = useNavigate();
    const error: any = useRouteError();

    console.error(error);

    return (
        <div className="text-white p-3">
            <div>{error?.message || `There is nothing here.`}</div>
            <PrimaryButton className="mt-2" onClick={() => navigate(routeNotesList())}>Go Back</PrimaryButton>
        </div>
    );
}