import React, {useEffect} from 'react'
import {createBrowserRouter , RouterProvider} from 'react-router-dom'
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {Requests} from '../../controller/requests';

import base from '../../routes/pwa/base/routes';
import calendars from '../../routes/pwa/calendars/routes';
import events from '../../routes/pwa/events/routes';
import goals from '../../routes/pwa/goals/routes';
import notes from '../../routes/pwa/notes/routes';
import tasks from '../../routes/pwa/tasks/routes';
import clocks from '../../routes/pwa/worldClocks/routes';


const router = createBrowserRouter([
    base,
    calendars,
    goals,
    notes,
    tasks,
    clocks,
    events,
])

export default function App () {

  useEffect(() => {
    sendRequest({
      _id: getTimestampId(),
      generic: 'request',
      type: Requests.initAccount,
    }).then(response => {
      console.log("INIT ACCOUNT");
      console.log(response);
    }).catch(error => {
      console.error(error);
    });
  }, [])

  return <RouterProvider router={router} />
}
