import {DocType, TaxonomyType} from './db/types';

export const routeCalendarView = (context?:string) => `/calendar/${context || 'all'}/view`;

export const routeNotesList = (context?:string) => `/notes/${context || 'all'}/list/`;
export const routeNotesListGet = (context?:string) => `/notes/${context || 'all'}/list/get`;
export const routeNotesEdit = (editorType: DocType, _id?: string, context?: string) => `/notes/${context || 'all'}/edit/${editorType}/${_id || ''}${_id ? '/' : ''}`;
export const routeNotesDelete = (_id: string, context?: string) => `/notes/${context || 'all'}/delete/${_id}`;
export const routeNotesView = (_id: string, context?: string) => `/notes/${context || 'all'}/view/${_id}`;
export const routeMessages = (_id: string, context?: string, entity?: string) => `/${entity || getEntity()}/${context || 'all'}/messages/${_id}`;

export const routeEventsList = (context?: string) => `/events/${context || 'all'}/list/`;
export const routeEventsListGet = (context?: string) => `/events/${context || 'all'}/list/get`;
export const routeEventsEdit = (_id?: string, context?: string) => `/events/${context || 'all'}/edit/${_id || ''}`;
export const routeEventsDelete = (_id: string, context?: string) => `/events/${context || 'all'}/delete/${_id}`;

export const routeGoalsList = (context?: string) => `/goals/${context || 'all'}/list/`;
export const routeGoalsListGet = (context?: string) => `/goals/${context || 'all'}/list/get`;
export const routeGoalsOverview = (context?: string) => `/goals/${context || 'all'}/overview/`;
export const routeGoalsEdit = ( _id?: string, context?: string) => `/goals/${context || 'all'}/edit/${_id || ''}${_id ? '/' : ''}`;
export const routeGoalsDelete = (_id: string, context?: string) => `/goals/${context || 'all'}/delete/${_id}`;

export const routeTasksList = (context?: string) => `/tasks/${context || 'all'}/list/`;
export const routeTasksListGet = (context?: string) => `/tasks/${context || 'all'}/list/get`;
export const routeTasksEdit = (editorType: DocType, _id?: string, context?: string) => `/tasks/${context || 'all'}/edit/${editorType}/${_id || ''}${_id ? '/' : ''}`;
export const routeTasksDelete = (_id: string, context?: string) => `/tasks/${context || 'all'}/delete/${_id}`;

export const routeTaxonomiesList = (type?: TaxonomyType, context?: string) => `/${getEntity()}/${context || 'all'}/taxonomies/list/${type}`
export const routeTaxonomiesListGet = (type?: TaxonomyType, context?: string) => `/${getEntity()}/${context || 'all'}/taxonomies/list/${type}/get`
export const routeTaxonomiesEdit = (editorType: TaxonomyType, _id?: string, context?: string) => `/${getEntity()}/${context || 'all'}/taxonomies/edit/${editorType}/${_id || ''}${_id ? '/' : ''}`;
export const routeTaxonomiesDelete = (_id: string, context?: string) => `/${getEntity()}/${context || 'all'}/taxonomies/delete/${_id}`;


export const routeBookmarksList = (context?: string) => `/bookmarks/${context || 'all'}/list/`;
export const routeBookmarksListGet = (context?: string) => `/bookmarks/${context || 'all'}/list/get`;
export const routeBookmarksEdit = (_id?: string, context?: string) => `/bookmarks/${context || 'all'}/edit/${_id || ''}${_id ? '/' : ''}`;
export const routeBookMarksDelete = (_id: string, context?: string) => `/bookmarks/${context || 'all'}/delete/${_id}`;
export const routeBookMarksView = (_id: string, context?: string) => `/bookmarks/${context || 'all'}/view/${_id}`;

export const routeEntityTaxonomiesList = (path: string, type?: TaxonomyType, context?: string) => `/${getTaxonomyEntity(path)}/${context || 'all'}/taxonomies/list/${type}`;
export const routeEntityTaxonomiesListGet = (path: string, type?: TaxonomyType, context?: string) => `/${getTaxonomyEntity(path)}/${context || 'all'}/taxonomies/list/${type}/get`;
export const routeEntityTaxonomiesEdit = (path: string, editorType: TaxonomyType, _id?: string, context?: string) => `/${getTaxonomyEntity(path)}/${context || 'all'}/taxonomies/edit/${editorType}/${_id || ''}${_id ? '/' : ''}`;
export const routeEntityTaxonomiesDelete = (path: string, _id: string, context?: string) => `/${getTaxonomyEntity(path)}/${context || 'all'}/taxonomies/delete/${_id}`;

export const routeWorldClocksList = (context?: string) => `/world-clocks/${context || 'all'}/list/`;
export const routeWorldClocksListGet = (context?: string) => `/world-clocks/${context || 'all'}/list/get`;
export const routeWorldClocksEdit = (_id?: string, context?: string) => `/world-clocks/${context || 'all'}/edit/${_id || ''}${_id ? '/' : ''}`;
export const routeWorldClocksDelete = (_id: string, context?: string) => `/world-clocks/${context || 'all'}/delete/${_id}`;
export const routeWorldClocksView = (_id: string, context?: string) => `/world-clocks/${context || 'all'}/view/${_id}`;

export const getEntity = () => {
    let entity = 'notes';

    if (window.location.href.includes('/goals')) {
        entity = 'goals';
    }

    if (window.location.href.includes('/bookmarks')) {
        entity = 'bookmarks';
    }

    if (window.location.href.includes('/tasks')) {
        entity = 'tasks';
    }

    if (window.location.href.includes('/events')) {
        entity = 'events';
    }

    if (window.location.href.includes('/world-clocks')) {
        entity = 'world-clocks';
    }

    return entity;
}

export const getTaxonomyEntity = (path: string = '') => {
    let entity = 'notes';

    if (path.includes('/goals')) {
        entity = 'goals';
    }

    if (path.includes('/bookmarks')) {
        entity = 'bookmarks';
    }

    if (path.includes('/tasks')) {
        entity = 'tasks';
    }

    if (path.includes('/events')) {
        entity = 'events';
    }

    return entity;
}