import {FormError, ResponseError} from '../../db/types';

export const getResponseError = (err: any, code: number = 499): ResponseError|undefined => {

    if (!err.code || Number.isNaN(Number(err.code)) || err.code > code) {
        return {
            message: `${err.code || 'general'}: ${err.message}`,
            stack: err.stack,
            type: 'warning'
        };
    }
    return undefined;
};

export const  makeFormErrors = (err: any, code: number = 499): FormError[] => {
    if (!err.code || err.code > code) {
        return [];
    }

    if (!err.data) {
        return [{key: 'general', message: err.message}];
    }


    const errors: FormError[] = [];

    for (const field in err.data) {
        errors.push({ key: field, message: err.data[field] });
    }
    return errors;
};


export const makeResponseErrors = (data: any): ResponseError[] => {
    const errors: ResponseError[] = [];

    for (const field in data) {
        errors.push({message: `${field}: ${data[field]}`, type: 'warning', stack: ''});
    }
    return errors;
};

export const hasError = (field: string, errors: FormError[]) =>
    errors.filter((error) => error.key == field)?.length > 0;
export const getError = (field: string, errors: FormError[]) =>
    errors.filter((error) => error.key == field).pop();