import React, {useCallback, useRef, useState} from 'react';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {Button, Form} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PasswordStrengthBar from 'react-password-strength-bar';
import configs from '../../../configs';
import {Requests} from '../../../controller/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {FormError} from '../../../db/types';
import {getError, hasError} from '../../../utils/common/errors';

export type Props = {
    onSuccess: (data: Data) => void,
    onCancel: () => void,
    onSignIn: () => void
}

export type Data = {
    email: string
    password: string
    message?: string
    agree_to_terms?: string,
}

export default function CreateAccount({onSuccess, onSignIn, onCancel}: Props) {
    const {addError, RenderedErrors, clearErrors, hasErrors, errors} = useResponseErrors();
    const [passwords, setPasswords] = useState({password: '', confirm: ''})
    const [email, setEmail] = useState("")
    const [terms, setTerms] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const ref = useRef<HTMLFormElement|null>(null)
    const [formErrors, setFormErrors] = useState<FormError[]>([]);

    const onNext = useCallback(() => {
        clearErrors()
        setFormErrors([])

        if (!email) {
            setFormErrors([{message: "Please enter a valid email.", key: "email"}]);
            return;
        }


        if (!passwords.password) {
            setFormErrors([{message: "Please enter a complex password.", key: "password"}]);
            return;
        }

        if (passwords.password.length < 9) {
            setFormErrors([{message:"Your password is too short. It must be at least 9 symbols.", key: "password"}]);
            return;
        }

        if (passwords.password.match(/^[a-z0-9]+$/gi)) {
            setFormErrors([{message:"Your password doesn't include a special symbol like one of these !@#$%^&*_+", key: "password"}]);
            return;
        }

        if (passwords.password !== passwords.confirm) {
            setFormErrors([{message:"The passwords don't match match.", key: "password"}]);
            return;
        }

        if (terms !== '1') {
            setFormErrors([{message:"We can't process your data if you don't agree with our terms.", key: "agree_to_terms"}]);
            return;
        }

        const data = {
            email: email,
            password: passwords.password,
            message: "Your account was created. Please sign in...",
            agree_to_terms: terms,
        }

        setIsProcessing(true)
        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.signUp,
            data: {...data},
        }).then(response => {
            setIsProcessing(false)
            if (response.error || response.formErrors) {
                response.error && addError(response.error);
                setFormErrors(response.formErrors || []);
                return;
            }

            setEmail('');
            setPasswords({password: '', confirm: ''});
            setTerms('');
            onSuccess(data);

        }).catch(error => {
            setIsProcessing(false)
            console.error(error)
        });
    }, [ref, email, clearErrors, passwords, errors, formErrors, setFormErrors, terms]);

    return (<>
        {hasErrors && <RenderedErrors className="mb-2" />}

        <Form ref={ref}>
            <Form.Group>
                <Form.Label className="text-white mb-2">Email</Form.Label>
                <Form.Control
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="Enter an email"
                    className="default-background"
                    isInvalid={hasError('email', formErrors)}
                />

                <Form.Control.Feedback type="invalid">
                    {getError('email', formErrors)?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="password" className="text-white mb-2">Password</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    isInvalid={hasError('password', formErrors)}
                    value={passwords.password}
                    onChange={(event) => setPasswords({...passwords, password: event.target.value})} placeholder="Enter a password"
                    className="default-background"
                />

                <Form.Control.Feedback type="invalid">
                    {getError('password', formErrors)?.message}
                </Form.Control.Feedback>

                <Form.Label htmlFor="confirm" className="text-white mt-3 mb-2">Confirm Password</Form.Label>
                <Form.Control
                    type="password"
                    name="confirm"
                    value={passwords.confirm}
                    onChange={(event) => setPasswords({...passwords, confirm: event.target.value})}
                    placeholder="Confirm your password"
                    className="default-background"
                />
                <PasswordStrengthBar password={passwords.password} className="mt-2" />
            </Form.Group>

            <Form.Group key="checkbox" className="my-2">
                <Form.Check
                    className="mt-0 mr-3 justify-content-start w-100 primary"
                    name="agree_to_terms"
                >
                    <Form.Check.Input
                        type="checkbox"
                        isInvalid={hasError('agree_to_terms', formErrors)}
                        name="agree_to_terms"
                        className="text-primary"
                        value="1"
                        checked={terms === '1'}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setTerms('1');
                                return;
                            }
                            setTerms('');
                        }}
                    />
                    <Form.Check.Label>
                        <div className="mb-0 ms-2 text-white">
                            I agree with your{' '}
                            <a
                                href="https://troitza.com/page/eula"
                                target="_blank"
                                rel="noreferrer"
                            >
                                EULA
                            </a>
                            ,
                            <a
                                href="https://troitza.com/page/terms-of-use"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Use
                            </a>{' '}
                            &amp;{' '}
                            <a
                                href="https://troitza.com/page/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                        {getError('agree_to_terms', formErrors)?.message}
                    </Form.Control.Feedback>
                </Form.Check>
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
               <div className="d-flex">
                   <Button type="button" variant="outline-primary" size="sm" disabled={isProcessing} onClick={onCancel}>Cancel</Button>
                   <Button variant="outline-light" size="sm" className="d-inline-block ms-2" disabled={isProcessing} onClick={() => onSignIn()}>Sign In</Button>
               </div>
                <PrimaryButton type="button" size="sm" onClick={onNext} isProcessing={isProcessing}>Next</PrimaryButton>
            </div>
        </Form>
    </>);

}