import React from 'react';

import contextLoader from '../../common/context/contextLoader';
import EventsLayout from '../../../components/Layout/Pwa/EventsLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';

const routes = {
    loader: contextLoader,
    path: '/events/:context',
    element: <EventsLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/events/:context/list',
            lazy: async () => {
                let {ListEventsScreen} = await import('../../common/events');
                return {Component: ListEventsScreen}
            },

            children: [
                {
                    path: '/events/:context/list/get',
                    lazy: async () => {
                        let {listLoader} = await import('../../common/events');
                        return {loader: listLoader}
                    },
                }
            ]
        },

        {
            shouldRevalidate: () => false,
            path: '/events/:context/delete/:_id',
            lazy: async () => {
                let {deleteAction, deleteLoader, DeleteEventScreen} = await import('../../common/events');
                return {loader: deleteLoader, action: deleteAction, Component: DeleteEventScreen}
            },
        },
        {
            shouldRevalidate: (data) => {
                return !data.formAction?.includes('/taxonomies');
            },
            path: '/events/:context/edit/:_id?',
            lazy: async () => {
                let {editAction, editLoader, EditEventScreen} = await import('../../common/events');
                return {loader: editAction, action: editLoader, Component: EditEventScreen}
            },
        },

        {

            path: '/events/:context/taxonomies/list/:type',
            lazy: async () => {
                let {ListTaxonomiesScreen} = await import('../../common/events');
                return {Component: ListTaxonomiesScreen}
            },

            children: [
                {
                    path: '/events/:context/taxonomies/list/:type/get',
                    lazy: async () => {
                        let {listTaxonomiesLoader} = await import('../../common/events');
                        return {loader: listTaxonomiesLoader}
                    },
                }
            ]
        },
        {
            path: '/events/:context/taxonomies/edit/:type/:_id?',
            lazy: async () => {
                let {editTaxonomyAction, editTaxonomyLoader, EditTaxonomyScreen} = await import('../../common/events');
                return {loader: editTaxonomyLoader, action: editTaxonomyAction, Component: EditTaxonomyScreen}
            },
        },

        {
            shouldRevalidate: () => false,
            path: '/events/:context/taxonomies/delete/:_id?',
            lazy: async () => {
                let {deleteTaxonomyAction, deleteTaxonomyLoader, DeleteTaxonomyScreen} = await import('../../common/events');
                return {loader: deleteTaxonomyLoader, action: deleteTaxonomyAction, Component: DeleteTaxonomyScreen}
            },
        },

        {
            loader: async ({params}) => {
                return {...params, entity: 'events'};
            },
            path: '/events/:context/messages/:messageId?',
            element: <MessageScreen />,
        },
    ]
};

export default routes;
