import React from 'react';
import {RouteObject} from 'react-router-dom';
import contextLoader from '../../common/context/contextLoader';
import GoalsLayout from '../../../components/Layout/Application/GoalsLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';

const routes: RouteObject =   {
    loader: contextLoader,
    path: '/goals/:context',
    element: <GoalsLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/goals/:context/list',
            lazy: async () => {
                let {ListGoalsScreen} = await import('../../common/goals');
                return {Component: ListGoalsScreen}
            },

            children: [
                {
                    path: '/goals/:context/list/get',
                    lazy: async () => {
                        let {listLoader} = await import('../../common/goals');
                        return {loader: listLoader}
                    },
                }
            ]
        },

        {
            path: '/goals/:context/overview',
            lazy: async () => {
                let {overviewLoader, OverviewGoalScreen} = await import('../../common/goals');
                return {loader: overviewLoader, Component: OverviewGoalScreen}
            },
        },

        {
            shouldRevalidate: () => false,
            path: '/goals/:context/delete/:_id',
            lazy: async () => {
                let {deleteLoader, deleteAction, DeleteGoalScreen} = await import('../../common/goals');
                return {loader: deleteLoader, action: deleteAction, Component: DeleteGoalScreen}
            },
        },
        {
            shouldRevalidate: (data) => {
                return !data.formAction?.includes('/taxonomies');
            },

            path: '/goals/:context/edit/:_id?/',
            lazy: async () => {
                let {editLoader, editAction, EditGoalScreen} = await import('../../common/goals');
                return {loader: editLoader, action: editAction, Component: EditGoalScreen}
            },
        },

        {
            path: '/goals/:context/taxonomies/list/:type',
            lazy: async () => {
                let {ListTaxonomiesScreen} = await import('../../common/goals');
                return {Component: ListTaxonomiesScreen}
            },

            children: [
                {
                    path: '/goals/:context/taxonomies/list/:type/get',
                    lazy: async () => {
                        let {listTaxonomiesLoader} = await import('../../common/goals');
                        return {loader: listTaxonomiesLoader}
                    },
                }
            ]
        },
        {
            path: '/goals/:context/taxonomies/edit/:type/:_id?',
            lazy: async () => {
                let {editTaxonomyAction, editTaxonomyLoader, EditTaxonomyScreen} = await import('../../common/goals');
                return {loader: editTaxonomyLoader, action: editTaxonomyAction, Component: EditTaxonomyScreen}
            },
        },

        {
            shouldRevalidate: () => false,
            path: '/goals/:context/taxonomies/delete/:_id?',
            lazy: async () => {
                let {deleteTaxonomyAction, deleteTaxonomyLoader, DeleteTaxonomyScreen} = await import('../../common/goals');
                return {loader: deleteTaxonomyLoader, action: deleteTaxonomyAction, Component: DeleteTaxonomyScreen}
            },
        },

        {
            loader: async ({params}) => {
                return {...params, entity: 'goals'};
            },
            path: '/goals/:context/messages/:messageId?',
            element: <MessageScreen />,
        },
    ]
};

export default routes;