import {RouteObject} from 'react-router-dom';
import contextLoader from '../../common/context/contextLoader';
import CalendarLayout from '../../../components/Layout/Pwa/CalendarLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import React from 'react';

const routes: RouteObject = {
    loader: contextLoader,
    path: '/calendar/:context',
    element: <CalendarLayout />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/calendar/:context/view',
            lazy: async () => {
                let {viewLoader, ViewCalendarScreen} = await import('../../common/calendars');
                return {loader: viewLoader, Component: ViewCalendarScreen}
            },
        },
    ]
};

export default routes;