export interface IDigitalGoodsService {
    getDetails(itemIds: string[]): Promise<ItemDetails[]>
    listPurchases(): Promise<PurchaseDetails[]>
    listPurchaseHistory():  Promise<PurchaseDetails[]>
    consume(purchaseToken: string): Promise<void>
    isSupported(): Promise<boolean>
}

export type PaymentCurrencyAmount = {
    value: string,
    currency: string,
};


export enum ItemType {
    PRODUCT = 'product',
    SUBSCRIPTION = 'subscription'
}


export type ItemDetails = {
    itemId: string,
    title: string,
    price: PaymentCurrencyAmount,
    itemType?: ItemType,
    description?: string,
    iconsUrls?: string[],
    subscriptionPeriod?: string,
    freeTrialPeriod?: string,
    introductoryPrice?: PaymentCurrencyAmount,
    introductoryPricePeriod?: string,
    introductoryPriceCycles?: number,
};

export type SubscriptionPlan = {
    itemId: string,
    title: string,

    value: string,
    currency: string
    default?: boolean,
};

export type PurchaseDetails = {
    itemId: string,
    purchaseToken: string;
}