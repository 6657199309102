import {Request, Response} from '../../../db/types';
import * as devConsole from '../../../utils/common/devConsole';
import {logRuntimeLastError} from '../../../utils/common/devConsole';
import delay from '../../../utils/common/delay';
import {Requests} from '../../../controller/requests';

export default class ChromeExtension {
    sendRequest(request: Request<any>): Promise<Response> {
        return new Promise((resolve) => {
            chrome.runtime.sendMessage(request, function(response) {
                devConsole.log('send request response');
                devConsole.dir(response);

                if (request.type.match(/list|remove|upsert/gi) && !request.type.match(/calendar/gi)) {
                    delay(600).then(() => {
                        logRuntimeLastError();
                        resolve(response)
                    }).catch(error => console.error(error))
                } else {
                    logRuntimeLastError();
                    resolve(response)
                }
            });

            logRuntimeLastError();
        })
    }
}