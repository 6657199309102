import React from 'react';
import {RouteObject} from 'react-router-dom';

import contextLoader from '../../common/context/contextLoader';
import NotebookLayout from '../../../components/Layout/Application/NotebookLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';
import makeListTaxonomiesLoader from '../../common/taxonomies/loaders/listLoader';



const routes: RouteObject = {
    loader: contextLoader,
        path: '/notes/:context',
    element: <NotebookLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [

    {
        path: '/notes/:context/list',
        lazy: async () => {
            let {ListNotesScreen} = await import('../../common/notes');
            return {Component: ListNotesScreen}
        },

        children: [
            {
                path: '/notes/:context/list/get',
                lazy: async () => {
                    let {listLoader} = await import('../../common/notes');
                    return {loader: listLoader}
                },
            }
        ]
    },
    {
        shouldRevalidate: () => false,
        path: '/notes/:context/delete/:_id',
        lazy: async () => {
            let {deleteLoader, deleteAction, DeleteNoteScreen} = await import('../../common/notes');
            return {loader: deleteLoader, action: deleteAction, Component: DeleteNoteScreen}
        },
    },
    {
        shouldRevalidate: (data) => {
            return !data.formAction?.includes('/taxonomies');
        },
        path: '/notes/:context/edit/:type/:_id?/',
        lazy: async () => {
            let {editLoader, editAction, EditNoteScreen} = await import('../../common/notes');
            return {loader: editLoader, action: editAction, Component: EditNoteScreen}
        },
    },
    {
        path: '/notes/:context/view/:_id',
        lazy: async () => {
            let {viewLoader, ViewNoteScreen} = await import('../../common/notes');
            return {loader: viewLoader, Component: ViewNoteScreen}
        },
    },

    {

        path: '/notes/:context/taxonomies/list/:type',
        lazy: async () => {
            let {ListTaxonomiesScreen} = await import('../../common/notes');
            return {Component: ListTaxonomiesScreen}
        },
        children: [
            {
                loader: makeListTaxonomiesLoader('notes'),
                path: '/notes/:context/taxonomies/list/:type/get'
            }
        ]
    },
    {
        path: '/notes/:context/taxonomies/edit/:type/:_id?',
        lazy: async () => {
            let {editTaxonomyAction, editTaxonomyLoader, EditTaxonomyScreen} = await import('../../common/notes');
            return {action: editTaxonomyAction, loader: editTaxonomyLoader,  Component: EditTaxonomyScreen}
        },
    },

    {
        shouldRevalidate: () => false,
        path: '/notes/:context/taxonomies/delete/:_id?',
        lazy: async () => {
            let {deleteTaxonomyAction, deleteTaxonomyLoader, DeleteTaxonomyScreen} = await import('../../common/notes');
            return {action: deleteTaxonomyAction, loader: deleteTaxonomyLoader,  Component: DeleteTaxonomyScreen}
        },
    },

    {
        loader: async ({params}) => {
            return {...params, entity: 'notes'};
        },
        path: '/notes/:context/messages/:messageId?',
        element: <MessageScreen />,
    },
]
}

export default routes;