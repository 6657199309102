import React, {useCallback, useEffect, useState} from 'react'
import PickModal from '../PickModal/PickModal';
import classnames from 'classnames';
import DefaultProps from '../../../types/DefaultProps';
import CreateAccount from './CreateAccount';
import SignIn from './SignIn';
import {AccountData} from '../../../db/types';
import Account from './Account';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {Requests} from '../../../controller/requests';
import {isTwaSession} from '../../../utils/common/mode';
import configs from '../../../configs';
import ForgotPassword from './ForgotPassword';
import PlanSelection from './PlanSelection';

export type Props = {
    show?: boolean
    onClose?: () => void
} & DefaultProps;


export type License = {success: boolean, message?: string, license_key: string, email: string};

export const titlesMap: Record<string, string> =  {
    'sign-in': 'Cloud Account - Sign In',
    'sign-up': 'Cloud Account - Sign Up',
    'signed-in': 'Cloud Account - Profile',
    'forgot': 'Cloud Account - Lost Password',
    'plan-selection': 'Cloud Account - Select Plan'
};

export default function AuthModal({show, onClose, className, style}: Props) {

    const [data, setData] = useState<AccountData|null>(null);
    const [modal, setModal] = useState<'sign-up'|'sign-in'|'signed-in'|'forgot'|'plan-selection'>('sign-in')
    const [title, setTitle] = useState(titlesMap['sign-in']);

    useEffect(() => {
        setTitle(titlesMap[modal]);
    }, [modal]);

    useEffect(() => {
        if (!data) {
            sendRequest({
                _id: getTimestampId(),
                generic: 'request',
                type: Requests.getAccountData,
            }).then(response => {
                if (!response.error && response.data) {
                    setModal('signed-in');
                    setData(() => {
                        return {...response.data} as AccountData
                    });
                }
            }).catch(error => console.error(error));
        }
    }, [data])

    const onSignUp = useCallback(() => {
        if (isTwaSession()) {
            setModal('sign-up');
        } else {
            setModal('sign-up');
           /// window.open(configs.accounts.signUpUrl);
        }
    }, [modal]);

    const onForgotPassword = useCallback(() => {
        setModal('forgot');
    }, [modal]);

    return (<PickModal
        show={show}
        title={title}
        className={classnames('auth-modal', className)}
        style={style}
        onClose={onClose}
    >

        {data && modal === 'signed-in' && <Account data={data} onClose={() => onClose && onClose()} onUpgrade={() => setModal('plan-selection')} />}
        {modal === 'sign-in' && <SignIn onForgotPassword={onForgotPassword} onCancel={() => onClose && onClose()} onSignUp={onSignUp} onSuccess={(data) => {
            setData(data)
            setModal('signed-in')
        }} message={data?.message} />}
        {modal === 'forgot' && <ForgotPassword onCancel={() => onClose && onClose()}/>}
        {modal === 'plan-selection' && <PlanSelection onClose={() => onClose && onClose()} />}
        {modal === 'sign-up' && <CreateAccount onCancel={() => onClose && onClose()} onSuccess={(data) => setModal('sign-in')} onSignIn={() => setModal('sign-in')} />}
    </PickModal>);
}