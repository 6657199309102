import {IDigitalGoodsService, ItemDetails, PurchaseDetails} from './types';


export default class Browser implements IDigitalGoodsService {

    constructor(protected storeId: string) {
    }

    async listPurchases(): Promise<PurchaseDetails[]> {
        const service = await this.getService();
        return service.listPurchases();
    }

    async getDetails(itemIds: string[]): Promise<ItemDetails[]> {
        const service = await this.getService();
        return service.getDetails(itemIds);
    }

    async listPurchaseHistory(): Promise<PurchaseDetails[]> {
        const service = await this.getService();
        return service.listPurchaseHistory();
    }

    async consume(purchaseToken: string): Promise<void> {
        const service = await this.getService();
        return service.consume(purchaseToken);
    }

    async isSupported(): Promise<boolean> {
        if ('getDigitalGoodsService' in window) {
            try {
                await this.getService();
                return true;
            } catch {
                return false;
            }
        }

        return false;
    }

    protected getService() {
        return (window as any).getDigitalGoodsService(this.storeId);
    }
}