import {IDigitalGoodsService, ItemDetails} from '../../digital-goods/Browser/types';
import BrowserService from '../../digital-goods/Browser/Browser';
import {defaultFreeSubscription} from '../../../utils/common/constants';

export default class Android {
    protected service: IDigitalGoodsService;
    protected storeId = 'https://play.google.com/billing';

    constructor() {
        this.service = new BrowserService(this.storeId);
    }

    isSupported() {
        return this.service.isSupported();
    }

    async getSubscriptionsDetails(itemsIds: string[]) {
        const subscriptions: ItemDetails[] = [];

        const items = await this.service.getDetails(itemsIds);

        for (const item of items) {
            subscriptions.push({...item});
        }

        subscriptions.push({...defaultFreeSubscription});

        return subscriptions;
    }
}