import React, {useState} from 'react';
import {FormError, ResponseError} from '../db/types';
import ErrorMessage from '../components/Common/ErrorMessage/ErrorMessage';
import defaultProps from '../types/DefaultProps';

export function useFormErrors() {
    const [errors, setErrors] = useState<FormError[]>([]);
    return ({
        errors,
        setErrors,
        addError: (error: FormError) => setErrors((errors) => [error, ...errors]),
        clearErrors: () => setErrors([]),
        RenderedErrors: ({className, style}: defaultProps) => <ErrorMessage errors={errors} className={className} style={style} />,
        hasErrors: errors.length > 0
    });
}