import {Outlet} from 'react-router-dom';
import React from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {
    routeCalendarView,
} from '../../../routes';
import LayoutNav from '../../Common/LayoutNav/LayoutNav';
import ApplicationLayout from './ApplicationLayout';

export type Props = DefaultProps;

export default function BaseLayout({style, className}: Props) {
    return (
        <ApplicationLayout className={classnames(className)} style={{...style}}>
            <LayoutNav addItem={{text: 'View calendar', to: routeCalendarView()}} items={[]} />
            <Outlet />
        </ApplicationLayout>)
}