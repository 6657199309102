import React from 'react';
import {RouteObject} from 'react-router-dom';
import contextLoader from '../../common/context/contextLoader';
import TasksLayout from '../../../components/Layout/Application/TasksLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';

const routes: RouteObject =   {
    loader: contextLoader,
    path: '/tasks/:context',
    element: <TasksLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/tasks/:context/list',
            lazy: async () => {
                let {ListTasksScreen} = await import('../../common/tasks');
                return {Component: ListTasksScreen}
            },
            children: [
                {
                    path: '/tasks/:context/list/get',
                    lazy: async () => {
                        let {listLoader} = await import('../../common/tasks');
                        return {loader: listLoader}
                    },
                }
            ]
        },

        {
            shouldRevalidate: () => false,
            path: '/tasks/:context/delete/:_id',
            lazy: async () => {
                let {deleteAction, deleteLoader, DeleteTaskScreen} = await import('../../common/tasks');
                return {loader: deleteLoader, action: deleteAction, Component: DeleteTaskScreen};
            },
        },

        {
            shouldRevalidate: (data) => {
                return !data.formAction?.includes('/taxonomies');
            },
            path: '/tasks/:context/edit/:type/:_id?/',
            lazy: async () => {
                let {editAction, editLoader, EditTaskScreen} = await import('../../common/tasks');
                return {loader: editLoader, action: editAction, Component: EditTaskScreen};
            },
        },

        {
            path: '/tasks/:context/taxonomies/list/:type',
            lazy: async () => {
                let {ListTaxonomiesScreen} = await import('../../common/tasks');
                return {Component: ListTaxonomiesScreen};
            },
            children: [
                {
                    path: '/tasks/:context/taxonomies/list/:type/get',
                    lazy: async () => {
                        let {listTaxonomiesLoader} = await import('../../common/tasks');
                        return {loader: listTaxonomiesLoader};
                    },
                }
            ]
        },
        {
            path: '/tasks/:context/taxonomies/edit/:type/:_id?',
            lazy: async () => {
                let {editTaxonomyAction, editTaxonomyLoader, EditTaxonomyScreen} = await import('../../common/tasks');
                return {loader: editTaxonomyLoader, action: editTaxonomyAction, Component: EditTaxonomyScreen};
            },
        },

        {
            shouldRevalidate: () => false,
            path: '/tasks/:context/taxonomies/delete/:_id?',
            lazy: async () => {
                let {deleteTaxonomyAction, deleteTaxonomyLoader, DeleteTaxonomyScreen} = await import('../../common/tasks');
                return {loader: deleteTaxonomyLoader, action: deleteTaxonomyAction, Component: DeleteTaxonomyScreen};
            },
        },

        {
            loader: async ({params}) => {
                return {...params, entity: 'tasks'};
            },
            path: '/tasks/:context/messages/:messageId?',
            element: <MessageScreen />,
        },
    ]
};

export default routes;