import {Requests} from '../../../controller/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import { redirect } from 'react-router-dom';
import {isInstalledPwaSession, isTwaSession} from '../../../utils/common/mode';

export default async function contextLoader({params})  {
    const isPwa = await isInstalledPwaSession();
    const isTwa = await isTwaSession();


    /*
    if (isPwa || isTwa) {
        const account = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.getAccountData,
        })

        if (!account.data) {
            throw  redirect('/sign-in');
        }

    }*/


    if (!params.context || params.context === 'all') {
        return {};
    }

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getGoal,
        data: {_id: params.context as string}
    });

    return {goal: response.data || null, routeContext: params.context, error: response.error};
}