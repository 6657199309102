import React, {useEffect, useState} from 'react';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {Requests} from '../../../controller/requests';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {ItemDetails, PaymentCurrencyAmount} from '../../../api/digital-goods/Browser/types';
import Android from '../../../api/subscription/Android/Android';
import {defaultSubscriptionItems, userLocale} from '../../../utils/common/constants';
import {Spinner} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

export type Props = {
    onClose: () => void,
}

export const formatPrice = ({value, currency}: PaymentCurrencyAmount) => {
    try {
        const numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency: currency.toLowerCase(),
            currencyDisplay: 'symbol'
        })

        return numberFormat.format(parseFloat(value));
    } catch {
        return `${currency} ${value}`;
    }
}

export default function PlanSelection({onClose}: Props) {
    const [state, setState] = useState<ItemDetails[]|undefined>()
    const {addError, clearErrors, RenderedErrors} = useResponseErrors();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const android = new Android();

        android.isSupported().then(isSupported => {
            if (isSupported) {
                new Android().getSubscriptionsDetails(Object.keys(defaultSubscriptionItems)).then(items => {
                    setState(items ? [...items] : []);
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                    console.error(error)
                });
            } else {
                setState([]);
                setIsLoading(false);
            }
        })

    }, []);

    return (<div>
        <RenderedErrors />
        {isLoading && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}
        {state && state.length < 1 && <div className="text-white mb-2 d-flex">No plans were found...</div>}
        {state && state.map(item => <Card className="bg-black text-light p-2 mb-2">

            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.description}</Card.Text>

            </Card.Body>
            <Card.Footer>
                <Card.Text className="h4">{formatPrice(item.price)}</Card.Text>
            </Card.Footer>
        </Card>)}
        <div></div>
    </div>);

}