import React from 'react';
import {RouteObject} from 'react-router-dom';
import WorldClocksLayout from '../../../components/Layout/Application/WorldClocksLayout';
import ApplicationError from '../../../components/Common/ApplicationError/ApplicationError';
import MessageScreen from '../../../components/Screen/Application/Common/MessageScreen/MessageScreen';


const routes: RouteObject =   {
    path: '/world-clocks',
    element: <WorldClocksLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
        {
            path: '/world-clocks/:context/list',
            lazy: async () => {
                let {ListWorldClocksScreen} = await import('../../common/worldClocks');
                return {Component: ListWorldClocksScreen}
            },
            children: [
                {
                    path: '/world-clocks/:context/list/get',
                    lazy: async () => {
                        let {listLoader} = await import('../../common/worldClocks');
                        return {loader: listLoader}
                    },
                }
            ]
        },
        {
            shouldRevalidate: () => false,
            path: '/world-clocks/:context/delete/:_id',
            lazy: async () => {
                let {deleteLoader, deleteAction, DeleteWorldClocksScreen} = await import('../../common/worldClocks');
                return {loader: deleteLoader, action: deleteAction, Component: DeleteWorldClocksScreen}
            },
        },
        {
            path: '/world-clocks/:context/edit/:_id?/',
            lazy: async () => {
                let {editLoader, editAction, EditWorldClocksScreen} = await import('../../common/worldClocks');
                return {loader: editLoader, action: editAction, Component: EditWorldClocksScreen}
            },
        },
        {
            loader: async ({params}) => {
                return params;
            },
            path: '/world-clocks/:context/messages/:messageId?',
            element: <MessageScreen />,
        },
    ]
};

export default routes;