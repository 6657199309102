import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Table} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {AccountData} from '../../../db/types';
import jwtDecode from 'jwt-decode';
import {formattedBytes} from '../../../utils/common/sizes';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {Requests} from '../../../controller/requests';
import {useResponseErrors} from '../../../hooks/useResponseErrors';

export type Props = {
    onClose: () => void,
    onUpgrade: () => void,
    data: AccountData
}

export type AccountState = {
    email: string,
    maxDbSize: string,
    maxDocSize: string,
    maxDevices: number,
    usedDbSize: string,
    documents: number,
    plan: string
}




export function getManageSubscriptionUrl(subscriptionId: string): string {
    return `https://app.gumroad.com/subscriptions/${subscriptionId}/manage`
}

export default function Account({onClose, data, onUpgrade}: Props) {
    const [state, setState] = useState<AccountState|null>(null)
    const {addError, clearErrors, RenderedErrors} = useResponseErrors();

    useEffect(() => {
        const decoded: any = jwtDecode(data.token)

        let state: AccountState = {
            email: decoded.email,
            plan: decoded.plan,
            maxDbSize: formattedBytes(decoded.max_db_size),
            maxDocSize: formattedBytes(decoded.max_doc_size),
            maxDevices: decoded.max_devices,
            usedDbSize: '',
            documents: 0,
        }

        if (data.vault) {
            state = {...state,
                usedDbSize: formattedBytes(decoded.max_db_size - data.vault.size.active),
                documents: data.vault.doc_count,
            };
        }

        setState(state);
    }, [data])

    const onSignOut = useCallback(() => {
        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.signOut,
        }).then(response => {
            if (response.data?.success) {
                window.location.reload();
            }

            if (response.error) {
                addError(response.error);
            }
        })
    }, [clearErrors, addError])

    return (<>
        {state && <Form>
            <Form.Group>
                <Form.Label className="text-white">Email</Form.Label>
                <Form.Control type="email" defaultValue={state.email} readOnly={true} />
            </Form.Group>

            <Form.Group className="my-4">
                <Form.Label className="text-white me-3">Plan: {state.plan}</Form.Label>
                {state.plan !== 'Free Users' ?
                    <Button variant="outline-light" size="sm">Manage Subscription</Button> :
                    <Button variant="outline-light" onClick={onUpgrade}>Upgrade</Button>
                }
            </Form.Group>

            <Form.Group className="my-3">
                <Table>
                    <tbody>
                    {state.usedDbSize &&
                        <tr>
                            <td>Available Space:</td>
                            <td>{state.usedDbSize} / {state.maxDbSize}</td>
                        </tr>
                    }
                        <tr>
                            <td>Max Document Size:</td>
                            <td>{state.maxDocSize}</td>
                        </tr>
                        <tr>
                            <td>Max Devices:</td>
                            <td>{state.maxDevices}</td>
                        </tr>

                    {state.documents > 0 &&
                        <tr>
                            <td>Saved Documents:</td>
                            <td>{state.documents}</td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Form.Group>


            <div className="d-flex justify-content-start mt-5">
                <Button type="button" variant="secondary" onClick={onClose}>Close</Button>
                <PrimaryButton type="button" variant="outline-primary" onClick={onSignOut} className="ms-2">Sign Out</PrimaryButton>
            </div>
            <RenderedErrors />
        </Form>}
    </>);

}