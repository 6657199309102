import React, {useState} from 'react'
import Icon from '../Icon/Icon';
import {
    routeBookmarksList,
    routeCalendarView, routeEventsList, routeGoalsEdit, routeGoalsList, routeGoalsOverview,
    routeNotesList,
    routeTasksList,
    routeWorldClocksList
} from '../../../routes';
import DefaultProps from '../../../types/DefaultProps';
import {useLocation, useNavigate, useMatch, useMatches} from 'react-router-dom';

import './ApplicationMenu.scss'
import AuthModal from '../Auth/AuthModal';
import {Button} from 'react-bootstrap';
import {isActiveRoute, makeRouteMatch} from '../../Mobile/Menu/Menu';


export default function ApplicationMenu(props: DefaultProps) {
    const navigate = useNavigate()
    const [showAuthModal, setShowAuthModal] = useState(false);
    const {pathname, state} = useLocation();

    const match = useMatch(makeRouteMatch(pathname));
    const context = match?.params?.param2 || 'all';
    const isAllContext = context == 'all';


    return (
        <div className="application-menu-wrapper">
            <div className="application-menu">

                {!isAllContext && <Button
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        navigate(routeGoalsList('all'));
                    }}
                >
                    <Icon
                        type="ti-arrow-circle-left"
                    />
                    Back
                </Button>}

                {!isAllContext && <Button
                    active={isActiveRoute(`/goals/${context}/overview`, pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => navigate(routeGoalsOverview(context))}
                >
                    <Icon
                        type="ti-pulse"
                    />
                    Overview
                </Button>}

                <Button
                    active={isActiveRoute("/calendar/", pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => navigate(routeCalendarView(context))}
                >
                    <Icon
                        type="ti-calendar"
                    />
                    Calendar
                </Button>


                <Button variant="outline-primary"
                        active={isActiveRoute('/goals/', pathname) && !isActiveRoute(`/goals/${context}/overview`, pathname)}
                        className="border-0"
                        onClick={() => navigate(routeGoalsList(context))}
                >
                    <Icon
                        type="ti-star"
                    />

                    Goals
                </Button>


                <Button
                    active={isActiveRoute('/tasks/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => navigate(routeTasksList(context))}
                >
                    <Icon
                        type="ti-check-box"
                    />

                    Tasks
                </Button>

                <Button
                    active={isActiveRoute('/events/', pathname)}
                    variant="outline-primary"
                    className="border-0 justify-content-center d-flex flex-column align-items-center"
                    onClick={() => navigate(routeEventsList(context))}
                >
                    <Icon
                        type="gg-calendar-today"
                    />

                    Events
                </Button>

                <Button
                    active={isActiveRoute('/notes/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => navigate(routeNotesList(context))}
                >
                    <Icon
                        type="ti-agenda"
                    />

                    Notes
                </Button>


                {isAllContext && <Button
                    variant="outline-primary"
                    className="border-0"
                    active={isActiveRoute('/bookmarks/', pathname)}
                    onClick={() => navigate(routeBookmarksList(context))}
                >
                    <Icon
                        type="ti-bookmark"
                    />
                    Bookmarks
                </Button>}

                {isAllContext && <Button
                    active={isActiveRoute('/world-clocks/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() =>
                        navigate(routeWorldClocksList())}
                >
                    <Icon
                        type="ti-time"
                    />

                    Clocks
                </Button>}


            </div>
            {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </div>
    )
}